@import "@snapatoms/ui/src/styles/helpers.scss";

$form-color: #8c8c8c;

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 18px; */
  color: #484848;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.form-container {
  width: 20rem;
  padding: 1rem;
}

@include below-large {
  .form-container {
    width: 100%;
  }
}

.icon-md {
  width: 5rem;
}

.icon-sm {
  width: 3rem;
}

.icon-xs {
  width: 1rem;
  margin: 0 0.5rem;
}

.key-text {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: #313336;
  background-color: var(--theme-pre-bg, #313336);
  background: #f0efed;
  border: 1px solid #313336;
  border-top-color: rgb(49, 51, 54);
  border-right-color: rgb(49, 51, 54);
  border-bottom-color: rgb(49, 51, 54);
  border-left-color: rgb(49, 51, 54);
  border-color: var(--theme-pre-bg, #313336);
  border-radius: 0.25rem;
  color: #a8b2ff;
  color: var(--theme-pre-fg, #a8b2ff);
  color: #000;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.875rem;
  font-size: 80%;
  font-weight: 500 !important;
  line-height: 2;
  padding: 0.25rem;
}
