.atoms-card {
  border: 1px solid #ccc;

  min-height: 10rem;
  background-color: #fcfcfc;
  // color: #202020;
  border-radius: 10px;

  &__header {
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    h3,
    h4 {
      margin: 0;
      padding: 0;
    }
  }

  &__body {
    padding: 1rem;
  }
}
