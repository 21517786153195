.atoms-header {
  display: flex;
  justify-content: space-between;
  background-color: #282c34;
  padding: 0 1rem;

  a,
  h3 {
    color: white;
    padding: 1rem;
    margin: 0;
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: #ccc;
    }
  }

  a div {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: inline;
    }
  }
}
