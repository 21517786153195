@keyframes fading {
  from {
    background-color: green;
  }

  to {
    background-color: white;
    color: black;
  }
}

.atoms-button {
  background-color: green;
  padding: 1rem;
  border: none;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  border-radius: 0.25rem;
  transition: background-color 50ms;

  &:hover {
    background-color: darken(green, 10%);
  }

  &:active {
    background-color: white;
    color: black;
  }

  &--sm {
    padding: 0.25rem;
    font-size: 0.75rem;
    max-width: 10rem;
  }

  &--xs {
    padding: 0.35rem;
    font-size: 0.5rem;
    max-width: 10rem;
  }

  & & {
    margin-right: 0.5rem;
  }

  //   &:focus {
  //     background-color: white;
  //     color: black;
  //   }
}
