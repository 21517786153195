.form-field {
  filter: unset !important;
  background-color: transparent;
  color: inherit;
  outline: none;
  border: 1px solid #757575;
  padding: 0.75rem;
  border-radius: 3px;
  font-size: inherit;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.form-theme-dark {
  .form-field {
    color: #fff;
  }
}
