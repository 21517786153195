$small: 640px;

$breakpoints: (
    "small": $small,
    "medium": 1007px,
    "large": 1008px,
    "xl": 1344px,
);

@mixin responsive($width: $small, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type==max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// all devices larger than $large
@mixin from-large {
    $width: map_get($breakpoints, "large");
    $width: $width - 1px;
    @media only screen and (#{min}-width: $width) {
        @content;
    }
}

// all devices larger than $large
@mixin below-large {
    $width: map_get($breakpoints, "large");
    $width: $width - 1px;
    @media only screen and (#{max}-width: $width) {
        @content;
    }
}

.desktop-only {
    @include below-large {
        display: none !important;
    }
}

.mobile-only {
    @include from-large {
        display: none !important;
    }
}

@include from-large {
    .desktop-relative {
        position: relative;
    }
}
