/*!**********************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js!./node_modules/postcss-loader/dist/cjs.js!./node_modules/sass-loader/dist/cjs.js!./src/styles/index.scss ***!
  \**********************************************************************************************************************************************************/
@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.container-fluid {
  display: block;
  box-sizing: inherit;
}

.container {
  margin: 0 auto;
  position: relative;
  width: auto;
  box-sizing: inherit;
  max-width: 1152px;
}
@media only screen and (min-width: 1344px) {
  .container {
    max-width: 1152px;
  }
}
.container--wide {
  max-width: 1344px;
}

.container-sm {
  margin: 0 auto;
  position: relative;
  width: auto;
  box-sizing: inherit;
}
@media only screen and (min-width: 1008px) {
  .container-sm {
    max-width: 640px;
  }
}

.columns {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

@media only screen and (min-width: 1008px) {
  .columns {
    display: flex;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    box-sizing: border-box;
  }
}
.columns--wrap {
  flex-wrap: wrap;
}
.columns--wrap > [class^=column-] {
  flex: none;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  box-sizing: inherit;
}

.column-1 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-1 {
    width: 8.3333333333%;
  }
}

.column-2 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-2 {
    width: 16.6666666667%;
  }
}

.column-3 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-3 {
    width: 25%;
  }
}

.column-4 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-4 {
    width: 33.3333333333%;
  }
}

.column-5 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-5 {
    width: 41.6666666667%;
  }
}

.column-6 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-6 {
    width: 50%;
  }
}

.column-7 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-7 {
    width: 58.3333333333%;
  }
}

.column-8 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-8 {
    width: 66.6666666667%;
  }
}

.column-9 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-9 {
    width: 75%;
  }
}

.column-10 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-10 {
    width: 83.3333333333%;
  }
}

.column-11 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-11 {
    width: 91.6666666667%;
  }
}

.column-12 {
  padding: 0.75rem;
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}
@media only screen and (min-width: 1008px) {
  .column-12 {
    width: 100%;
  }
}

.column-full {
  flex: none;
  width: 100%;
}

.column-half {
  flex: none;
  width: 50%;
}

.column-one-quarter {
  flex: none;
  width: 25%;
}

.column-one-third {
  flex: none;
  width: 33%;
}

.column-two-thirds {
  flex: none;
  width: 66%;
}

.column-three-quarters {
  flex: none;
  width: 75%;
}

.columns--tight > * {
  margin: 0;
  padding: 0 !important;
}

.is-multiline {
  flex-wrap: wrap;
}

.is-tight {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.is-tight > .column {
  margin: 0;
  padding: 0 !important;
}

.is-tight > * {
  padding: 0;
}

.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}
.row--multiline {
  flex-wrap: wrap;
}
@media only screen and (min-width: 1008px) {
  .row {
    display: flex;
  }
}
.row--tight {
  margin: 0;
}
.row--tight .grid,
.row--tight *[class*=col-] {
  padding: 0;
}

.col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1, .col {
  display: block;
  box-sizing: inherit;
  padding: 0.75rem;
}

.col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.col-1 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-1 {
    width: 8.3333333333%;
  }
}

.row--mobile > .col-1 {
  width: 8.3333333333%;
}

.col-2 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-2 {
    width: 16.6666666667%;
  }
}

.row--mobile > .col-2 {
  width: 16.6666666667%;
}

.col-3 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-3 {
    width: 25%;
  }
}

.row--mobile > .col-3 {
  width: 25%;
}

.col-4 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-4 {
    width: 33.3333333333%;
  }
}

.row--mobile > .col-4 {
  width: 33.3333333333%;
}

.col-5 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-5 {
    width: 41.6666666667%;
  }
}

.row--mobile > .col-5 {
  width: 41.6666666667%;
}

.col-6 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-6 {
    width: 50%;
  }
}

.row--mobile > .col-6 {
  width: 50%;
}

.col-7 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-7 {
    width: 58.3333333333%;
  }
}

.row--mobile > .col-7 {
  width: 58.3333333333%;
}

.col-8 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-8 {
    width: 66.6666666667%;
  }
}

.row--mobile > .col-8 {
  width: 66.6666666667%;
}

.col-9 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-9 {
    width: 75%;
  }
}

.row--mobile > .col-9 {
  width: 75%;
}

.col-10 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-10 {
    width: 83.3333333333%;
  }
}

.row--mobile > .col-10 {
  width: 83.3333333333%;
}

.col-11 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-11 {
    width: 91.6666666667%;
  }
}

.row--mobile > .col-11 {
  width: 91.6666666667%;
}

.col-12 {
  flex: none;
}
@media only screen and (min-width: 1008px) {
  .col-12 {
    width: 100%;
  }
}

.row--mobile > .col-12 {
  width: 100%;
}

.row--mobile {
  display: flex;
}

.uni-headings h1,
.uni-headings h2,
.uni-headings h3,
.uni-headings h4,
.uni-headings h5,
.uni-headings h6 {
  font-size: 1.25rem;
  padding: 1rem 0;
  margin: 1rem 0 1rem 0;
}

.h1-rulers h1 {
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
  margin: 1rem 0 1rem 0;
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
:root {
  --theme-lighten-factor: 1;
  --charcoal-bg-color: #353535;
  --charcoal-fg-color: rgb(226, 226, 226);
  --navy-bg-color: #141728;
  --navy-fg-color: #ccc;
  --theme-alert-bg: goldenrod;
  --theme-alert-dark-bg: rgb(179, 128, 0);
  --theme-info-bg: rgb(0, 140, 255);
  --theme-info-dark-bg: rgb(0, 115, 205);
}

.alert, .button--alert {
  background-color: var(--theme-alert-bg);
}

.info, .button--info {
  background-color: var(--theme-info-bg);
}

.charcoal {
  background-color: #353535;
  color: rgb(226, 226, 226);
}

@media only screen and (max-width: 1007px) {
  .mobile-charcoal {
    background-color: var(--charcoal-bg-color);
    color: var(--charcoal-fg-color);
  }
  .mobile-charcoal .border-bottom-dashed {
    border-color: rgb(88, 88, 88);
  }
  .mobile-navy {
    background-color: var(--navy-bg-color);
    color: var(--navy-fg-color);
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-white {
    background-color: white;
  }
  .mobile-fg-white {
    background-color: white;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-white {
    background-color: white;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-black {
    background-color: black;
  }
  .mobile-fg-black {
    background-color: black;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-black {
    background-color: black;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-green {
    background-color: green;
  }
  .mobile-fg-green {
    background-color: green;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-green {
    background-color: green;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-red {
    background-color: red;
  }
  .mobile-fg-red {
    background-color: red;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-red {
    background-color: red;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-cyan {
    background-color: cyan;
  }
  .mobile-fg-cyan {
    background-color: cyan;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-cyan {
    background-color: cyan;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-yellow {
    background-color: yellow;
  }
  .mobile-fg-yellow {
    background-color: yellow;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-yellow {
    background-color: yellow;
  }
}
.button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  align-items: center;
  position: relative;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-appearance: none;
  transition: all 80ms linear;
  color: #fff;
  font-weight: 700;
  box-shadow: none;
  padding: 0.5rem 1rem;
}
.button:hover {
  color: #fff;
  background: #148567;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.button::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 4px;
  visibility: hidden;
}
.button {
  background: #007a5a;
}
.button--small {
  font-size: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.button--small-group > .button {
  font-size: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.button--medium {
  font-size: 15px;
  height: 36px;
  min-width: 80px;
  padding: 0 12px 1px;
}
.button--square {
  border-radius: 0;
}
.button--alert:hover {
  background-color: var(--theme-alert-dark-bg);
}
.button--info:hover {
  background-color: var(--theme-info-dark-bg);
}

.expand::after {
  float: right;
  color: #777;
  font-weight: bold;
  transition: 200ms;
  content: "+";
  padding: 0 0.5rem;
}
.expand--center::after {
  float: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.expand--active::after {
  transform: rotate(180deg);
  content: "-";
}

.expand-box {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0.1rem 0.3rem;
  margin: 0 0.25rem;
  color: black;
}
.expand-box::after {
  content: "+";
}

.expand-container:hover .expand-box {
  border-color: black;
}

.expand--hidden .expand::after {
  display: none;
}

:root {
  --handle-bg: green;
  --handle-fg: white;
}

.handle {
  width: 3rem;
  height: 3rem;
  background-color: var(--handle-bg);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--handle-fg);
  border-radius: 0.5rem;
  z-index: 50;
  opacity: 0.5;
}
.handle--active, .handle--active > .handle {
  opacity: 1;
}
.handle--left-edge {
  border-radius: 0.5rem 0 0 0.5rem;
  left: 0;
}
.handle--right-edge {
  border-radius: 0.5rem 0 0 0.5rem;
  right: 100%;
}

.hamburger-box {
  position: fixed;
  left: 0;
  top: 0;
}

.hamburger {
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

.hamburger span,
.hamburger span:before,
.hamburger span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 30px;
  background: rgb(78, 78, 78);
  position: absolute;
  display: inline-block;
  content: "";
}

.hamburger span:before {
  top: -10px;
}

.hamburger span:after {
  bottom: -10px;
}

/*
.hamburger span, .hamburger span:before, .hamburger span:after {
  transition: all 200ms ease-in-out;
}
*/
.hamburger span,
.hamburger span:before,
.hamburger span:after {
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
}

.hamburger--active span {
  background-color: transparent;
}

.hamburger--active span:before,
.hamburger--active span:after {
  top: 0;
}

.hamburger--active span:before {
  transform: rotate(45deg);
}

.hamburger--active span:after {
  transform: translateY(-10px) rotate(-45deg);
  top: 10px;
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
:root {
  --menu-bg-color: #272822;
  --menu-fg-color: white;
  --menu-fg-secondary-color: goldenrod;
  --menu-fg-tertiary-color: cyan;
}

.menu {
  background-color: var(--menu-bg-color);
  color: var(--menu-fg-color);
  height: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 4rem;
  transition: 200ms all;
}
.menu__inner {
  position: relative;
}
@media only screen and (min-width: 1007px) {
  .menu--desktop-open, .menu--open {
    width: 20rem;
  }
}
.menu--fixed {
  position: fixed;
  height: 100%;
  z-index: 100;
}
@media only screen and (max-width: 1007px) {
  .menu {
    width: 75%;
    transform: translateX(-100%);
  }
  .menu--open {
    transform: translateX(0);
  }
}
.menu--hidden p,
.menu--hidden ul,
.menu--hidden li {
  display: none;
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.drawer-mobile,
.drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .drawer-mobile {
    z-index: 10;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transition: 100ms;
    transform: translateX(-100%);
  }
  .drawer-mobile--right {
    right: 0;
    transform: none;
    left: 100%;
  }
  .drawer-mobile--open {
    transform: translateX(0%);
  }
  .drawer-mobile--overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
:root {
  --snapatoms-panel-shadow: 0 0 0.25rem 0 #8a888869;
  --snapatoms-panel-background: transparent;
  --snapatoms-panel-border: #e5e7eb;
  --snapatoms-panel-padding: 1.5rem;
  --snapatoms-panel-margin: 0;
}

.panel:not(.panel--desktop, .panel--mobile) {
  box-sizing: inherit;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
  padding: var(--snapatoms-panel-padding);
  margin: var(--snapatoms-panel-margin);
  box-shadow: var(--snapatoms-panel-shadow);
  background-color: var(--snapatoms-panel-bg, transparent);
}

.panel :is(h1, h2, h3, h4, h5, h6):first-child {
  margin-top: 0;
}
@media only screen and (max-width: 1007px) {
  .panel--mobile {
    box-sizing: inherit;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
    padding: var(--snapatoms-panel-padding);
    margin: var(--snapatoms-panel-margin);
    box-shadow: var(--snapatoms-panel-shadow);
    background-color: var(--snapatoms-panel-bg, transparent);
  }
}
@media only screen and (min-width: 1007px) {
  .panel--desktop {
    box-sizing: inherit;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
    padding: var(--snapatoms-panel-padding);
    margin: var(--snapatoms-panel-margin);
    box-shadow: var(--snapatoms-panel-shadow);
    background-color: var(--snapatoms-panel-bg, transparent);
  }
}

.accordion + * {
  display: none;
}
.accordion--active + * {
  display: block;
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.navbar {
  --snapatoms-navbar-bg: transparent;
  --snapatoms-navbar-fg: inherit;
  position: relative;
  transition: 250ms all;
  background-color: var(--snapatoms-navbar-bg);
}
.navbar__inner {
  width: 100%;
  display: flex;
}
@media only screen and (max-width: 1007px) {
  .navbar__inner {
    flex-direction: column;
  }
}
.navbar__group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
@media only screen and (max-width: 1007px) {
  .navbar__group {
    flex-direction: column;
    align-items: flex-start;
  }
}
.navbar__link {
  padding: 1rem;
  text-decoration: none;
  display: block;
}
.navbar__item {
  pointer-events: auto;
  position: relative;
}
.navbar__nested {
  pointer-events: auto;
  display: none;
}
@media only screen and (max-width: 1007px) {
  .navbar--mobile-bordered .navbar__item {
    border-bottom: 1px solid rgb(78, 78, 78);
  }
  .navbar__item .navbar__item {
    box-sizing: border-box;
    padding: 0 0 0 1rem;
  }
  .navbar__item {
    width: 100%;
  }
}
.navbar__nested {
  position: relative;
  padding: 0;
}
@media only screen and (min-width: 1007px) {
  .navbar__nested {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 100;
    display: none;
    background-color: var(--theme-bg);
  }
  .navbar__item:hover > .navbar__nested, .navbar__nested:hover {
    display: block;
  }
}
.navbar--show + .navbar__nested {
  display: block;
}
.navbar--show > .navbar__nested {
  display: block;
}

.notice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  border-left: 0.5rem solid blue;
  min-width: 20rem;
  z-index: 100;
}
.notice__text {
  padding: 1rem;
}
.notice-close-button {
  border-left: 1px solid #ccc;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: rgb(0, 126, 126);
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.dock {
  position: fixed;
  right: 100px;
  width: 300px;
  min-height: 100px;
  transform: translate(-50%, 95%);
  bottom: 0;
  font-size: 13px !important;
  border-radius: 5px 5px 0 0;
  --snapatoms-dock-bg: #1a1a1a;
  --snapatoms-dock-fg: white;
  background-color: var(--snapatoms-dock-bg);
  color: var(--snapatoms-dock-fg);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3) !important;
}
.dock__toggle {
  border-radius: 5px 5px 0 0;
  background-color: var(--snapatoms-dock-bg);
  text-align: center;
  position: absolute;
  top: -18px;
  right: 20px;
  width: 40px;
  height: 18px;
  padding-top: 2px;
  cursor: pointer;
  border: none;
}
@media only screen and (max-width: 1007px) {
  .dock {
    right: auto;
    left: 50%;
  }
}
.dock--open {
  transform: translateX(-50%);
}
@media only screen and (max-width: 1007px) {
  .dock--open {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

:root {
  --snapatoms-box-shadow: 0 0 1rem 0rem #eee;
  --snapatoms-border-radius: 0.5rem;
  --snapatoms-border-outline: #e5e7eb;
}

.table-container {
  border-radius: var(--snapatoms-border-radius);
  box-shadow: var(--snapatoms-box-shadow);
  border-style: solid;
  border-color: var(--snapatoms-border-outline);
  border-radius: 10px;
  border: none;
  overflow: auto;
  height: auto;
}

.table {
  --snapatoms-table-bg: #009879;
  position: relative;
  display: table;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.75rem;
}
.table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.table th {
  line-height: 1.4;
  text-transform: uppercase;
  padding-top: 18px;
  padding-bottom: 18px;
}
.table th,
.table td {
  padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #dddddd;
}
.table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.table--stripped tbody tr:nth-of-type(even) {
  background-color: #f4f4f4;
}
.table tbody tr:hover {
  background-color: rgb(247, 247, 247);
}
.table--active-row {
  font-weight: bold;
  color: #009879;
}

.form-group {
  margin: 1rem 0;
  position: relative;
  padding: 0;
}

/* Remove filter on all fields */
input {
  filter: unset !important;
}

/* Remove color effect filter on autofilled fields */
:-moz-autofill {
  filter: unset !important;
}

.form-control {
  padding: 0.75rem 0.75rem;
  outline: none;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  font-size: inherit;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .form-control {
    font-size: 16px;
  }
}

.form-mask {
  position: absolute;
  right: 3%;
  font-size: 0.75rem;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #828282;
}
.form-mask:hover {
  color: #b5b5b5;
}

* {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}
input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: forwards;
}

:root {
  --snapatoms-loading-bg: #bbbbbb;
}

.loading-spinner-lr {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading-spinner-lr div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--snapatoms-loading-bg);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-spinner-lr div:nth-child(1) {
  left: 8px;
  animation: loading-spinner-lr1 0.6s infinite;
}

.loading-spinner-lr div:nth-child(2) {
  left: 8px;
  animation: loading-spinner-lr2 0.6s infinite;
}

.loading-spinner-lr div:nth-child(3) {
  left: 32px;
  animation: loading-spinner-lr2 0.6s infinite;
}

.loading-spinner-lr div:nth-child(4) {
  left: 56px;
  animation: loading-spinner-lr3 0.6s infinite;
}

@keyframes loading-spinner-lr1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-spinner-lr3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-spinner-lr2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.flex, .flex-wrap {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-inline {
  display: inline-flex;
}

.flex-0 {
  flex: 0 0 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-flex-col,
  .mobile-flex-col * {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
.border-left-dashed, .border-left, .border-bottom-dashed, .border-bottom, .border-right-dashed, .border-right, .border-top-dashed, .border-top {
  border-color: #e5e7eb;
}

.snap-right, .fixed-right, .fixed-left-right, .fixed-top-bottom, .fixed-top, .hide-right {
  position: fixed;
}

.border {
  border: 1px solid #ccc;
}

.border-dashed {
  border-style: dashed;
  border-width: 0;
}

@media only screen and (min-width: 1007px) {
  .desktop-w0 {
    width: 00%;
  }
  .desktop-p0 {
    padding: 0rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m0 {
    margin: 0rem;
  }
}
.m0-tb-all > * {
  margin: 0rem 0;
}

.p0-tb-all > * {
  padding: 0rem 0;
}

.p-tb-0 {
  padding: 0rem 0;
}

.p0-tb-0 {
  padding: 0.0rem 0 0.0rem 0;
}

.p0-rl-0 {
  padding: 0 0.0rem 0 0.0rem;
}

.m0-tb-0 {
  margin: 0.0rem 0 0.0rem 0;
}

.m0-rl-0 {
  margin: 0 0.0rem 0 0.0rem;
}

.p-tb-0 {
  padding: 0rem 0 0rem 0;
}

.p-rl-0 {
  padding: 0 0rem 0 0rem;
}

.m-tb-0 {
  margin: 0rem 0 0rem 0;
}

.m-rl-0 {
  margin: 0 0rem 0 0rem;
}

.w0 {
  width: 0rem;
}

.h0 {
  height: 0rem;
}

.s0 {
  height: 0rem;
  width: 0rem;
}

.m0 {
  margin: 0rem;
}

.p0 {
  padding: 0rem;
}

.m00 {
  margin: 0.0rem;
}

.p00 {
  padding: 0.0rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w1 {
    width: 10%;
  }
  .desktop-p1 {
    padding: 1rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m1 {
    margin: 1rem;
  }
}
.m1-tb-all > * {
  margin: 1rem 0;
}

.p1-tb-all > * {
  padding: 1rem 0;
}

.p-tb-1 {
  padding: 1rem 0;
}

.p0-tb-1 {
  padding: 0.1rem 0 0.1rem 0;
}

.p0-rl-1 {
  padding: 0 0.1rem 0 0.1rem;
}

.m0-tb-1 {
  margin: 0.1rem 0 0.1rem 0;
}

.m0-rl-1 {
  margin: 0 0.1rem 0 0.1rem;
}

.p-tb-1 {
  padding: 1rem 0 1rem 0;
}

.p-rl-1 {
  padding: 0 1rem 0 1rem;
}

.m-tb-1 {
  margin: 1rem 0 1rem 0;
}

.m-rl-1 {
  margin: 0 1rem 0 1rem;
}

.w1 {
  width: 1rem;
}

.h1 {
  height: 1rem;
}

.s1 {
  height: 1rem;
  width: 1rem;
}

.m1 {
  margin: 1rem;
}

.p1 {
  padding: 1rem;
}

.m01 {
  margin: 0.1rem;
}

.p01 {
  padding: 0.1rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w2 {
    width: 20%;
  }
  .desktop-p2 {
    padding: 2rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m2 {
    margin: 2rem;
  }
}
.m2-tb-all > * {
  margin: 2rem 0;
}

.p2-tb-all > * {
  padding: 2rem 0;
}

.p-tb-2 {
  padding: 2rem 0;
}

.p0-tb-2 {
  padding: 0.2rem 0 0.2rem 0;
}

.p0-rl-2 {
  padding: 0 0.2rem 0 0.2rem;
}

.m0-tb-2 {
  margin: 0.2rem 0 0.2rem 0;
}

.m0-rl-2 {
  margin: 0 0.2rem 0 0.2rem;
}

.p-tb-2 {
  padding: 2rem 0 2rem 0;
}

.p-rl-2 {
  padding: 0 2rem 0 2rem;
}

.m-tb-2 {
  margin: 2rem 0 2rem 0;
}

.m-rl-2 {
  margin: 0 2rem 0 2rem;
}

.w2 {
  width: 2rem;
}

.h2 {
  height: 2rem;
}

.s2 {
  height: 2rem;
  width: 2rem;
}

.m2 {
  margin: 2rem;
}

.p2 {
  padding: 2rem;
}

.m02 {
  margin: 0.2rem;
}

.p02 {
  padding: 0.2rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w3 {
    width: 30%;
  }
  .desktop-p3 {
    padding: 3rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m3 {
    margin: 3rem;
  }
}
.m3-tb-all > * {
  margin: 3rem 0;
}

.p3-tb-all > * {
  padding: 3rem 0;
}

.p-tb-3 {
  padding: 3rem 0;
}

.p0-tb-3 {
  padding: 0.3rem 0 0.3rem 0;
}

.p0-rl-3 {
  padding: 0 0.3rem 0 0.3rem;
}

.m0-tb-3 {
  margin: 0.3rem 0 0.3rem 0;
}

.m0-rl-3 {
  margin: 0 0.3rem 0 0.3rem;
}

.p-tb-3 {
  padding: 3rem 0 3rem 0;
}

.p-rl-3 {
  padding: 0 3rem 0 3rem;
}

.m-tb-3 {
  margin: 3rem 0 3rem 0;
}

.m-rl-3 {
  margin: 0 3rem 0 3rem;
}

.w3 {
  width: 3rem;
}

.h3 {
  height: 3rem;
}

.s3 {
  height: 3rem;
  width: 3rem;
}

.m3 {
  margin: 3rem;
}

.p3 {
  padding: 3rem;
}

.m03 {
  margin: 0.3rem;
}

.p03 {
  padding: 0.3rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w4 {
    width: 40%;
  }
  .desktop-p4 {
    padding: 4rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m4 {
    margin: 4rem;
  }
}
.m4-tb-all > * {
  margin: 4rem 0;
}

.p4-tb-all > * {
  padding: 4rem 0;
}

.p-tb-4 {
  padding: 4rem 0;
}

.p0-tb-4 {
  padding: 0.4rem 0 0.4rem 0;
}

.p0-rl-4 {
  padding: 0 0.4rem 0 0.4rem;
}

.m0-tb-4 {
  margin: 0.4rem 0 0.4rem 0;
}

.m0-rl-4 {
  margin: 0 0.4rem 0 0.4rem;
}

.p-tb-4 {
  padding: 4rem 0 4rem 0;
}

.p-rl-4 {
  padding: 0 4rem 0 4rem;
}

.m-tb-4 {
  margin: 4rem 0 4rem 0;
}

.m-rl-4 {
  margin: 0 4rem 0 4rem;
}

.w4 {
  width: 4rem;
}

.h4 {
  height: 4rem;
}

.s4 {
  height: 4rem;
  width: 4rem;
}

.m4 {
  margin: 4rem;
}

.p4 {
  padding: 4rem;
}

.m04 {
  margin: 0.4rem;
}

.p04 {
  padding: 0.4rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w5 {
    width: 50%;
  }
  .desktop-p5 {
    padding: 5rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m5 {
    margin: 5rem;
  }
}
.m5-tb-all > * {
  margin: 5rem 0;
}

.p5-tb-all > * {
  padding: 5rem 0;
}

.p-tb-5 {
  padding: 5rem 0;
}

.p0-tb-5 {
  padding: 0.5rem 0 0.5rem 0;
}

.p0-rl-5 {
  padding: 0 0.5rem 0 0.5rem;
}

.m0-tb-5 {
  margin: 0.5rem 0 0.5rem 0;
}

.m0-rl-5 {
  margin: 0 0.5rem 0 0.5rem;
}

.p-tb-5 {
  padding: 5rem 0 5rem 0;
}

.p-rl-5 {
  padding: 0 5rem 0 5rem;
}

.m-tb-5 {
  margin: 5rem 0 5rem 0;
}

.m-rl-5 {
  margin: 0 5rem 0 5rem;
}

.w5 {
  width: 5rem;
}

.h5 {
  height: 5rem;
}

.s5 {
  height: 5rem;
  width: 5rem;
}

.m5 {
  margin: 5rem;
}

.p5 {
  padding: 5rem;
}

.m05 {
  margin: 0.5rem;
}

.p05 {
  padding: 0.5rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w6 {
    width: 60%;
  }
  .desktop-p6 {
    padding: 6rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m6 {
    margin: 6rem;
  }
}
.m6-tb-all > * {
  margin: 6rem 0;
}

.p6-tb-all > * {
  padding: 6rem 0;
}

.p-tb-6 {
  padding: 6rem 0;
}

.p0-tb-6 {
  padding: 0.6rem 0 0.6rem 0;
}

.p0-rl-6 {
  padding: 0 0.6rem 0 0.6rem;
}

.m0-tb-6 {
  margin: 0.6rem 0 0.6rem 0;
}

.m0-rl-6 {
  margin: 0 0.6rem 0 0.6rem;
}

.p-tb-6 {
  padding: 6rem 0 6rem 0;
}

.p-rl-6 {
  padding: 0 6rem 0 6rem;
}

.m-tb-6 {
  margin: 6rem 0 6rem 0;
}

.m-rl-6 {
  margin: 0 6rem 0 6rem;
}

.w6 {
  width: 6rem;
}

.h6 {
  height: 6rem;
}

.s6 {
  height: 6rem;
  width: 6rem;
}

.m6 {
  margin: 6rem;
}

.p6 {
  padding: 6rem;
}

.m06 {
  margin: 0.6rem;
}

.p06 {
  padding: 0.6rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w7 {
    width: 70%;
  }
  .desktop-p7 {
    padding: 7rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m7 {
    margin: 7rem;
  }
}
.m7-tb-all > * {
  margin: 7rem 0;
}

.p7-tb-all > * {
  padding: 7rem 0;
}

.p-tb-7 {
  padding: 7rem 0;
}

.p0-tb-7 {
  padding: 0.7rem 0 0.7rem 0;
}

.p0-rl-7 {
  padding: 0 0.7rem 0 0.7rem;
}

.m0-tb-7 {
  margin: 0.7rem 0 0.7rem 0;
}

.m0-rl-7 {
  margin: 0 0.7rem 0 0.7rem;
}

.p-tb-7 {
  padding: 7rem 0 7rem 0;
}

.p-rl-7 {
  padding: 0 7rem 0 7rem;
}

.m-tb-7 {
  margin: 7rem 0 7rem 0;
}

.m-rl-7 {
  margin: 0 7rem 0 7rem;
}

.w7 {
  width: 7rem;
}

.h7 {
  height: 7rem;
}

.s7 {
  height: 7rem;
  width: 7rem;
}

.m7 {
  margin: 7rem;
}

.p7 {
  padding: 7rem;
}

.m07 {
  margin: 0.7rem;
}

.p07 {
  padding: 0.7rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w8 {
    width: 80%;
  }
  .desktop-p8 {
    padding: 8rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m8 {
    margin: 8rem;
  }
}
.m8-tb-all > * {
  margin: 8rem 0;
}

.p8-tb-all > * {
  padding: 8rem 0;
}

.p-tb-8 {
  padding: 8rem 0;
}

.p0-tb-8 {
  padding: 0.8rem 0 0.8rem 0;
}

.p0-rl-8 {
  padding: 0 0.8rem 0 0.8rem;
}

.m0-tb-8 {
  margin: 0.8rem 0 0.8rem 0;
}

.m0-rl-8 {
  margin: 0 0.8rem 0 0.8rem;
}

.p-tb-8 {
  padding: 8rem 0 8rem 0;
}

.p-rl-8 {
  padding: 0 8rem 0 8rem;
}

.m-tb-8 {
  margin: 8rem 0 8rem 0;
}

.m-rl-8 {
  margin: 0 8rem 0 8rem;
}

.w8 {
  width: 8rem;
}

.h8 {
  height: 8rem;
}

.s8 {
  height: 8rem;
  width: 8rem;
}

.m8 {
  margin: 8rem;
}

.p8 {
  padding: 8rem;
}

.m08 {
  margin: 0.8rem;
}

.p08 {
  padding: 0.8rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w9 {
    width: 90%;
  }
  .desktop-p9 {
    padding: 9rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m9 {
    margin: 9rem;
  }
}
.m9-tb-all > * {
  margin: 9rem 0;
}

.p9-tb-all > * {
  padding: 9rem 0;
}

.p-tb-9 {
  padding: 9rem 0;
}

.p0-tb-9 {
  padding: 0.9rem 0 0.9rem 0;
}

.p0-rl-9 {
  padding: 0 0.9rem 0 0.9rem;
}

.m0-tb-9 {
  margin: 0.9rem 0 0.9rem 0;
}

.m0-rl-9 {
  margin: 0 0.9rem 0 0.9rem;
}

.p-tb-9 {
  padding: 9rem 0 9rem 0;
}

.p-rl-9 {
  padding: 0 9rem 0 9rem;
}

.m-tb-9 {
  margin: 9rem 0 9rem 0;
}

.m-rl-9 {
  margin: 0 9rem 0 9rem;
}

.w9 {
  width: 9rem;
}

.h9 {
  height: 9rem;
}

.s9 {
  height: 9rem;
  width: 9rem;
}

.m9 {
  margin: 9rem;
}

.p9 {
  padding: 9rem;
}

.m09 {
  margin: 0.9rem;
}

.p09 {
  padding: 0.9rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-w10 {
    width: 100%;
  }
  .desktop-p10 {
    padding: 10rem;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-m10 {
    margin: 10rem;
  }
}
.m10-tb-all > * {
  margin: 10rem 0;
}

.p10-tb-all > * {
  padding: 10rem 0;
}

.p-tb-10 {
  padding: 10rem 0;
}

.p0-tb-10 {
  padding: 0.10rem 0 0.10rem 0;
}

.p0-rl-10 {
  padding: 0 0.10rem 0 0.10rem;
}

.m0-tb-10 {
  margin: 0.10rem 0 0.10rem 0;
}

.m0-rl-10 {
  margin: 0 0.10rem 0 0.10rem;
}

.p-tb-10 {
  padding: 10rem 0 10rem 0;
}

.p-rl-10 {
  padding: 0 10rem 0 10rem;
}

.m-tb-10 {
  margin: 10rem 0 10rem 0;
}

.m-rl-10 {
  margin: 0 10rem 0 10rem;
}

.w10 {
  width: 10rem;
}

.h10 {
  height: 10rem;
}

.s10 {
  height: 10rem;
  width: 10rem;
}

.m10 {
  margin: 10rem;
}

.p10 {
  padding: 10rem;
}

.m010 {
  margin: 0.10rem;
}

.p010 {
  padding: 0.10rem;
}

@media only screen and (max-width: 1007px) {
  .mobile-abs-top-0 {
    position: absolute;
    top: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .desktop-abs-top-0 {
    position: absolute;
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-top {
    position: fixed;
    top: 0;
    top: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-top-top-desktop {
    position: fixed;
    top: 0;
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-top-top-mobile {
    position: fixed;
    top: 0;
    top: 0;
  }
}
.fixed-top-top {
  position: fixed;
  top: 0;
  top: 0;
}

.abs-top-top {
  position: absolute;
  top: 0;
  top: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-right {
    position: fixed;
    top: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-top-right-desktop {
    position: fixed;
    top: 0;
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-top-right-mobile {
    position: fixed;
    top: 0;
    right: 0;
  }
}
.fixed-top-right {
  position: fixed;
  top: 0;
  right: 0;
}

.abs-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-bottom {
    position: fixed;
    top: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-top-bottom-desktop {
    position: fixed;
    top: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-top-bottom-mobile {
    position: fixed;
    top: 0;
    bottom: 0;
  }
}
.fixed-top-bottom {
  position: fixed;
  top: 0;
  bottom: 0;
}

.abs-top-bottom {
  position: absolute;
  top: 0;
  bottom: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-left {
    position: fixed;
    top: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-top-left-desktop {
    position: fixed;
    top: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-top-left-mobile {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

.abs-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-abs-right-0 {
    position: absolute;
    right: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .desktop-abs-right-0 {
    position: absolute;
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-top {
    position: fixed;
    right: 0;
    top: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-right-top-desktop {
    position: fixed;
    right: 0;
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-right-top-mobile {
    position: fixed;
    right: 0;
    top: 0;
  }
}
.fixed-right-top {
  position: fixed;
  right: 0;
  top: 0;
}

.abs-right-top {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-right {
    position: fixed;
    right: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-right-right-desktop {
    position: fixed;
    right: 0;
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-right-right-mobile {
    position: fixed;
    right: 0;
    right: 0;
  }
}
.fixed-right-right {
  position: fixed;
  right: 0;
  right: 0;
}

.abs-right-right {
  position: absolute;
  right: 0;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-right-bottom-desktop {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-right-bottom-mobile {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
.fixed-right-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
}

.abs-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-left {
    position: fixed;
    right: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-right-left-desktop {
    position: fixed;
    right: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-right-left-mobile {
    position: fixed;
    right: 0;
    left: 0;
  }
}
.fixed-right-left {
  position: fixed;
  right: 0;
  left: 0;
}

.abs-right-left {
  position: absolute;
  right: 0;
  left: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-abs-bottom-0 {
    position: absolute;
    bottom: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .desktop-abs-bottom-0 {
    position: absolute;
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-top {
    position: fixed;
    bottom: 0;
    top: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-bottom-top-desktop {
    position: fixed;
    bottom: 0;
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-bottom-top-mobile {
    position: fixed;
    bottom: 0;
    top: 0;
  }
}
.fixed-bottom-top {
  position: fixed;
  bottom: 0;
  top: 0;
}

.abs-bottom-top {
  position: absolute;
  bottom: 0;
  top: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-bottom-right-desktop {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-bottom-right-mobile {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}
.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.abs-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-bottom {
    position: fixed;
    bottom: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-bottom-bottom-desktop {
    position: fixed;
    bottom: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-bottom-bottom-mobile {
    position: fixed;
    bottom: 0;
    bottom: 0;
  }
}
.fixed-bottom-bottom {
  position: fixed;
  bottom: 0;
  bottom: 0;
}

.abs-bottom-bottom {
  position: absolute;
  bottom: 0;
  bottom: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-left {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-bottom-left-desktop {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-bottom-left-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
.fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
}

.abs-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-abs-left-0 {
    position: absolute;
    left: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .desktop-abs-left-0 {
    position: absolute;
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-top {
    position: fixed;
    left: 0;
    top: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-left-top-desktop {
    position: fixed;
    left: 0;
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-left-top-mobile {
    position: fixed;
    left: 0;
    top: 0;
  }
}
.fixed-left-top {
  position: fixed;
  left: 0;
  top: 0;
}

.abs-left-top {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-right {
    position: fixed;
    left: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-left-right-desktop {
    position: fixed;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-left-right-mobile {
    position: fixed;
    left: 0;
    right: 0;
  }
}
.fixed-left-right {
  position: fixed;
  left: 0;
  right: 0;
}

.abs-left-right {
  position: absolute;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-left-bottom-desktop {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-left-bottom-mobile {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
.fixed-left-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
}

.abs-left-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-left {
    position: fixed;
    left: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1007px) {
  .fixed-left-left-desktop {
    position: fixed;
    left: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .fixed-left-left-mobile {
    position: fixed;
    left: 0;
    left: 0;
  }
}
.fixed-left-left {
  position: fixed;
  left: 0;
  left: 0;
}

.abs-left-left {
  position: absolute;
  left: 0;
  left: 0;
}

@media only screen and (min-width: 1007px) {
  .desktop-top {
    top: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-top {
    top: 0;
  }
}
.fixed-top {
  position: fixed;
  top: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top {
    position: fixed;
    top: 0;
  }
  .mobile-h100 {
    height: 100%;
  }
}
.abs-top {
  position: absolute;
  top: 0;
}

.border-top {
  border-top: 1px solid #ccc;
}

.border-top-dashed {
  border-top: 1px dashed #ccc;
}

@media only screen and (min-width: 1007px) {
  .fixed-top-top-desktop {
    position: fixed;
    top: 0;
    top: 0;
  }
}
.fixed-top-top {
  position: fixed;
  top: 0;
  top: 0;
}

.abs-top-top {
  position: absolute;
  top: 0;
  top: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-top-right-desktop {
    position: fixed;
    top: 0;
    right: 0;
  }
}
.fixed-top-right {
  position: fixed;
  top: 0;
  right: 0;
}

.abs-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-top-bottom-desktop {
    position: fixed;
    top: 0;
    bottom: 0;
  }
}
.fixed-top-bottom {
  position: fixed;
  top: 0;
  bottom: 0;
}

.abs-top-bottom {
  position: absolute;
  top: 0;
  bottom: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-top-left-desktop {
    position: fixed;
    top: 0;
    left: 0;
  }
}
.fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

.abs-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-top-auto {
  top: auto;
}

.fixed-top-0 {
  position: fixed;
  top: 0;
}

.fixed-top-0pc {
  position: fixed;
  top: 0%;
}

.fixed-top-00pc {
  position: fixed;
  top: 00%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-0 {
    position: fixed;
    top: 0;
  }
  .mobile-fixed-top-00pc {
    position: fixed;
    top: 00%;
  }
  .mobile-fixed-top-m00pc {
    position: fixed;
    top: -00%;
  }
  .mobile-abs-top-0 {
    position: absolute;
    top: 0;
  }
  .mobile-abs-top-00pc {
    position: absolute;
    top: 00%;
  }
}
.top-00pc {
  top: 00%;
}

.top-0 {
  top: 0rem;
}

.abs-top-0-neg {
  position: absolute;
  top: -0rem;
}

.abs-top-0 {
  position: absolute;
  top: 0rem;
}

.m0-top-all *:not(:last-child) {
  margin-top: 0rem;
}

.m-top-0 {
  margin-top: 0rem;
}

.p-top-0 {
  padding-top: 0rem;
}

.fixed-top-1 {
  position: fixed;
  top: 1;
}

.fixed-top-1pc {
  position: fixed;
  top: 1%;
}

.fixed-top-10pc {
  position: fixed;
  top: 10%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-1 {
    position: fixed;
    top: 1;
  }
  .mobile-fixed-top-10pc {
    position: fixed;
    top: 10%;
  }
  .mobile-fixed-top-m10pc {
    position: fixed;
    top: -10%;
  }
  .mobile-abs-top-1 {
    position: absolute;
    top: 1;
  }
  .mobile-abs-top-10pc {
    position: absolute;
    top: 10%;
  }
}
.top-10pc {
  top: 10%;
}

.top-1 {
  top: 1rem;
}

.abs-top-1-neg {
  position: absolute;
  top: -1rem;
}

.abs-top-1 {
  position: absolute;
  top: 1rem;
}

.m1-top-all *:not(:last-child) {
  margin-top: 1rem;
}

.m-top-1 {
  margin-top: 1rem;
}

.p-top-1 {
  padding-top: 1rem;
}

.fixed-top-2 {
  position: fixed;
  top: 2;
}

.fixed-top-2pc {
  position: fixed;
  top: 2%;
}

.fixed-top-20pc {
  position: fixed;
  top: 20%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-2 {
    position: fixed;
    top: 2;
  }
  .mobile-fixed-top-20pc {
    position: fixed;
    top: 20%;
  }
  .mobile-fixed-top-m20pc {
    position: fixed;
    top: -20%;
  }
  .mobile-abs-top-2 {
    position: absolute;
    top: 2;
  }
  .mobile-abs-top-20pc {
    position: absolute;
    top: 20%;
  }
}
.top-20pc {
  top: 20%;
}

.top-2 {
  top: 2rem;
}

.abs-top-2-neg {
  position: absolute;
  top: -2rem;
}

.abs-top-2 {
  position: absolute;
  top: 2rem;
}

.m2-top-all *:not(:last-child) {
  margin-top: 2rem;
}

.m-top-2 {
  margin-top: 2rem;
}

.p-top-2 {
  padding-top: 2rem;
}

.fixed-top-3 {
  position: fixed;
  top: 3;
}

.fixed-top-3pc {
  position: fixed;
  top: 3%;
}

.fixed-top-30pc {
  position: fixed;
  top: 30%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-3 {
    position: fixed;
    top: 3;
  }
  .mobile-fixed-top-30pc {
    position: fixed;
    top: 30%;
  }
  .mobile-fixed-top-m30pc {
    position: fixed;
    top: -30%;
  }
  .mobile-abs-top-3 {
    position: absolute;
    top: 3;
  }
  .mobile-abs-top-30pc {
    position: absolute;
    top: 30%;
  }
}
.top-30pc {
  top: 30%;
}

.top-3 {
  top: 3rem;
}

.abs-top-3-neg {
  position: absolute;
  top: -3rem;
}

.abs-top-3 {
  position: absolute;
  top: 3rem;
}

.m3-top-all *:not(:last-child) {
  margin-top: 3rem;
}

.m-top-3 {
  margin-top: 3rem;
}

.p-top-3 {
  padding-top: 3rem;
}

.fixed-top-4 {
  position: fixed;
  top: 4;
}

.fixed-top-4pc {
  position: fixed;
  top: 4%;
}

.fixed-top-40pc {
  position: fixed;
  top: 40%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-4 {
    position: fixed;
    top: 4;
  }
  .mobile-fixed-top-40pc {
    position: fixed;
    top: 40%;
  }
  .mobile-fixed-top-m40pc {
    position: fixed;
    top: -40%;
  }
  .mobile-abs-top-4 {
    position: absolute;
    top: 4;
  }
  .mobile-abs-top-40pc {
    position: absolute;
    top: 40%;
  }
}
.top-40pc {
  top: 40%;
}

.top-4 {
  top: 4rem;
}

.abs-top-4-neg {
  position: absolute;
  top: -4rem;
}

.abs-top-4 {
  position: absolute;
  top: 4rem;
}

.m4-top-all *:not(:last-child) {
  margin-top: 4rem;
}

.m-top-4 {
  margin-top: 4rem;
}

.p-top-4 {
  padding-top: 4rem;
}

.fixed-top-5 {
  position: fixed;
  top: 5;
}

.fixed-top-5pc {
  position: fixed;
  top: 5%;
}

.fixed-top-50pc {
  position: fixed;
  top: 50%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-5 {
    position: fixed;
    top: 5;
  }
  .mobile-fixed-top-50pc {
    position: fixed;
    top: 50%;
  }
  .mobile-fixed-top-m50pc {
    position: fixed;
    top: -50%;
  }
  .mobile-abs-top-5 {
    position: absolute;
    top: 5;
  }
  .mobile-abs-top-50pc {
    position: absolute;
    top: 50%;
  }
}
.top-50pc {
  top: 50%;
}

.top-5 {
  top: 5rem;
}

.abs-top-5-neg {
  position: absolute;
  top: -5rem;
}

.abs-top-5 {
  position: absolute;
  top: 5rem;
}

.m5-top-all *:not(:last-child) {
  margin-top: 5rem;
}

.m-top-5 {
  margin-top: 5rem;
}

.p-top-5 {
  padding-top: 5rem;
}

.fixed-top-6 {
  position: fixed;
  top: 6;
}

.fixed-top-6pc {
  position: fixed;
  top: 6%;
}

.fixed-top-60pc {
  position: fixed;
  top: 60%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-6 {
    position: fixed;
    top: 6;
  }
  .mobile-fixed-top-60pc {
    position: fixed;
    top: 60%;
  }
  .mobile-fixed-top-m60pc {
    position: fixed;
    top: -60%;
  }
  .mobile-abs-top-6 {
    position: absolute;
    top: 6;
  }
  .mobile-abs-top-60pc {
    position: absolute;
    top: 60%;
  }
}
.top-60pc {
  top: 60%;
}

.top-6 {
  top: 6rem;
}

.abs-top-6-neg {
  position: absolute;
  top: -6rem;
}

.abs-top-6 {
  position: absolute;
  top: 6rem;
}

.m6-top-all *:not(:last-child) {
  margin-top: 6rem;
}

.m-top-6 {
  margin-top: 6rem;
}

.p-top-6 {
  padding-top: 6rem;
}

.fixed-top-7 {
  position: fixed;
  top: 7;
}

.fixed-top-7pc {
  position: fixed;
  top: 7%;
}

.fixed-top-70pc {
  position: fixed;
  top: 70%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-7 {
    position: fixed;
    top: 7;
  }
  .mobile-fixed-top-70pc {
    position: fixed;
    top: 70%;
  }
  .mobile-fixed-top-m70pc {
    position: fixed;
    top: -70%;
  }
  .mobile-abs-top-7 {
    position: absolute;
    top: 7;
  }
  .mobile-abs-top-70pc {
    position: absolute;
    top: 70%;
  }
}
.top-70pc {
  top: 70%;
}

.top-7 {
  top: 7rem;
}

.abs-top-7-neg {
  position: absolute;
  top: -7rem;
}

.abs-top-7 {
  position: absolute;
  top: 7rem;
}

.m7-top-all *:not(:last-child) {
  margin-top: 7rem;
}

.m-top-7 {
  margin-top: 7rem;
}

.p-top-7 {
  padding-top: 7rem;
}

.fixed-top-8 {
  position: fixed;
  top: 8;
}

.fixed-top-8pc {
  position: fixed;
  top: 8%;
}

.fixed-top-80pc {
  position: fixed;
  top: 80%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-8 {
    position: fixed;
    top: 8;
  }
  .mobile-fixed-top-80pc {
    position: fixed;
    top: 80%;
  }
  .mobile-fixed-top-m80pc {
    position: fixed;
    top: -80%;
  }
  .mobile-abs-top-8 {
    position: absolute;
    top: 8;
  }
  .mobile-abs-top-80pc {
    position: absolute;
    top: 80%;
  }
}
.top-80pc {
  top: 80%;
}

.top-8 {
  top: 8rem;
}

.abs-top-8-neg {
  position: absolute;
  top: -8rem;
}

.abs-top-8 {
  position: absolute;
  top: 8rem;
}

.m8-top-all *:not(:last-child) {
  margin-top: 8rem;
}

.m-top-8 {
  margin-top: 8rem;
}

.p-top-8 {
  padding-top: 8rem;
}

.fixed-top-9 {
  position: fixed;
  top: 9;
}

.fixed-top-9pc {
  position: fixed;
  top: 9%;
}

.fixed-top-90pc {
  position: fixed;
  top: 90%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-9 {
    position: fixed;
    top: 9;
  }
  .mobile-fixed-top-90pc {
    position: fixed;
    top: 90%;
  }
  .mobile-fixed-top-m90pc {
    position: fixed;
    top: -90%;
  }
  .mobile-abs-top-9 {
    position: absolute;
    top: 9;
  }
  .mobile-abs-top-90pc {
    position: absolute;
    top: 90%;
  }
}
.top-90pc {
  top: 90%;
}

.top-9 {
  top: 9rem;
}

.abs-top-9-neg {
  position: absolute;
  top: -9rem;
}

.abs-top-9 {
  position: absolute;
  top: 9rem;
}

.m9-top-all *:not(:last-child) {
  margin-top: 9rem;
}

.m-top-9 {
  margin-top: 9rem;
}

.p-top-9 {
  padding-top: 9rem;
}

.fixed-top-10 {
  position: fixed;
  top: 10;
}

.fixed-top-10pc {
  position: fixed;
  top: 10%;
}

.fixed-top-100pc {
  position: fixed;
  top: 100%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-top-10 {
    position: fixed;
    top: 10;
  }
  .mobile-fixed-top-100pc {
    position: fixed;
    top: 100%;
  }
  .mobile-fixed-top-m100pc {
    position: fixed;
    top: -100%;
  }
  .mobile-abs-top-10 {
    position: absolute;
    top: 10;
  }
  .mobile-abs-top-100pc {
    position: absolute;
    top: 100%;
  }
}
.top-100pc {
  top: 100%;
}

.top-10 {
  top: 10rem;
}

.abs-top-10-neg {
  position: absolute;
  top: -10rem;
}

.abs-top-10 {
  position: absolute;
  top: 10rem;
}

.m10-top-all *:not(:last-child) {
  margin-top: 10rem;
}

.m-top-10 {
  margin-top: 10rem;
}

.p-top-10 {
  padding-top: 10rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-top {
    right: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-top {
    right: 0;
  }
}
.fixed-right {
  position: fixed;
  right: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right {
    position: fixed;
    right: 0;
  }
  .mobile-h100 {
    height: 100%;
  }
}
.abs-right {
  position: absolute;
  right: 0;
}

.border-right {
  border-right: 1px solid #ccc;
}

.border-right-dashed {
  border-right: 1px dashed #ccc;
}

@media only screen and (min-width: 1007px) {
  .fixed-right-top-desktop {
    position: fixed;
    right: 0;
    top: 0;
  }
}
.fixed-right-top {
  position: fixed;
  right: 0;
  top: 0;
}

.abs-right-top {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-right-right-desktop {
    position: fixed;
    right: 0;
    right: 0;
  }
}
.fixed-right-right {
  position: fixed;
  right: 0;
  right: 0;
}

.abs-right-right {
  position: absolute;
  right: 0;
  right: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-right-bottom-desktop {
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
.fixed-right-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
}

.abs-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-right-left-desktop {
    position: fixed;
    right: 0;
    left: 0;
  }
}
.fixed-right-left {
  position: fixed;
  right: 0;
  left: 0;
}

.abs-right-left {
  position: absolute;
  right: 0;
  left: 0;
}

.mobile-right-auto {
  right: auto;
}

.fixed-right-0 {
  position: fixed;
  right: 0;
}

.fixed-right-0pc {
  position: fixed;
  right: 0%;
}

.fixed-right-00pc {
  position: fixed;
  right: 00%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-0 {
    position: fixed;
    right: 0;
  }
  .mobile-fixed-right-00pc {
    position: fixed;
    right: 00%;
  }
  .mobile-fixed-right-m00pc {
    position: fixed;
    right: -00%;
  }
  .mobile-abs-right-0 {
    position: absolute;
    right: 0;
  }
  .mobile-abs-right-00pc {
    position: absolute;
    right: 00%;
  }
}
.right-00pc {
  right: 00%;
}

.right-0 {
  right: 0rem;
}

.abs-right-0-neg {
  position: absolute;
  right: -0rem;
}

.abs-right-0 {
  position: absolute;
  right: 0rem;
}

.m0-right-all *:not(:last-child) {
  margin-right: 0rem;
}

.m-right-0 {
  margin-right: 0rem;
}

.p-right-0 {
  padding-right: 0rem;
}

.fixed-right-1 {
  position: fixed;
  right: 1;
}

.fixed-right-1pc {
  position: fixed;
  right: 1%;
}

.fixed-right-10pc {
  position: fixed;
  right: 10%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-1 {
    position: fixed;
    right: 1;
  }
  .mobile-fixed-right-10pc {
    position: fixed;
    right: 10%;
  }
  .mobile-fixed-right-m10pc {
    position: fixed;
    right: -10%;
  }
  .mobile-abs-right-1 {
    position: absolute;
    right: 1;
  }
  .mobile-abs-right-10pc {
    position: absolute;
    right: 10%;
  }
}
.right-10pc {
  right: 10%;
}

.right-1 {
  right: 1rem;
}

.abs-right-1-neg {
  position: absolute;
  right: -1rem;
}

.abs-right-1 {
  position: absolute;
  right: 1rem;
}

.m1-right-all *:not(:last-child) {
  margin-right: 1rem;
}

.m-right-1 {
  margin-right: 1rem;
}

.p-right-1 {
  padding-right: 1rem;
}

.fixed-right-2 {
  position: fixed;
  right: 2;
}

.fixed-right-2pc {
  position: fixed;
  right: 2%;
}

.fixed-right-20pc {
  position: fixed;
  right: 20%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-2 {
    position: fixed;
    right: 2;
  }
  .mobile-fixed-right-20pc {
    position: fixed;
    right: 20%;
  }
  .mobile-fixed-right-m20pc {
    position: fixed;
    right: -20%;
  }
  .mobile-abs-right-2 {
    position: absolute;
    right: 2;
  }
  .mobile-abs-right-20pc {
    position: absolute;
    right: 20%;
  }
}
.right-20pc {
  right: 20%;
}

.right-2 {
  right: 2rem;
}

.abs-right-2-neg {
  position: absolute;
  right: -2rem;
}

.abs-right-2 {
  position: absolute;
  right: 2rem;
}

.m2-right-all *:not(:last-child) {
  margin-right: 2rem;
}

.m-right-2 {
  margin-right: 2rem;
}

.p-right-2 {
  padding-right: 2rem;
}

.fixed-right-3 {
  position: fixed;
  right: 3;
}

.fixed-right-3pc {
  position: fixed;
  right: 3%;
}

.fixed-right-30pc {
  position: fixed;
  right: 30%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-3 {
    position: fixed;
    right: 3;
  }
  .mobile-fixed-right-30pc {
    position: fixed;
    right: 30%;
  }
  .mobile-fixed-right-m30pc {
    position: fixed;
    right: -30%;
  }
  .mobile-abs-right-3 {
    position: absolute;
    right: 3;
  }
  .mobile-abs-right-30pc {
    position: absolute;
    right: 30%;
  }
}
.right-30pc {
  right: 30%;
}

.right-3 {
  right: 3rem;
}

.abs-right-3-neg {
  position: absolute;
  right: -3rem;
}

.abs-right-3 {
  position: absolute;
  right: 3rem;
}

.m3-right-all *:not(:last-child) {
  margin-right: 3rem;
}

.m-right-3 {
  margin-right: 3rem;
}

.p-right-3 {
  padding-right: 3rem;
}

.fixed-right-4 {
  position: fixed;
  right: 4;
}

.fixed-right-4pc {
  position: fixed;
  right: 4%;
}

.fixed-right-40pc {
  position: fixed;
  right: 40%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-4 {
    position: fixed;
    right: 4;
  }
  .mobile-fixed-right-40pc {
    position: fixed;
    right: 40%;
  }
  .mobile-fixed-right-m40pc {
    position: fixed;
    right: -40%;
  }
  .mobile-abs-right-4 {
    position: absolute;
    right: 4;
  }
  .mobile-abs-right-40pc {
    position: absolute;
    right: 40%;
  }
}
.right-40pc {
  right: 40%;
}

.right-4 {
  right: 4rem;
}

.abs-right-4-neg {
  position: absolute;
  right: -4rem;
}

.abs-right-4 {
  position: absolute;
  right: 4rem;
}

.m4-right-all *:not(:last-child) {
  margin-right: 4rem;
}

.m-right-4 {
  margin-right: 4rem;
}

.p-right-4 {
  padding-right: 4rem;
}

.fixed-right-5 {
  position: fixed;
  right: 5;
}

.fixed-right-5pc {
  position: fixed;
  right: 5%;
}

.fixed-right-50pc {
  position: fixed;
  right: 50%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-5 {
    position: fixed;
    right: 5;
  }
  .mobile-fixed-right-50pc {
    position: fixed;
    right: 50%;
  }
  .mobile-fixed-right-m50pc {
    position: fixed;
    right: -50%;
  }
  .mobile-abs-right-5 {
    position: absolute;
    right: 5;
  }
  .mobile-abs-right-50pc {
    position: absolute;
    right: 50%;
  }
}
.right-50pc {
  right: 50%;
}

.right-5 {
  right: 5rem;
}

.abs-right-5-neg {
  position: absolute;
  right: -5rem;
}

.abs-right-5 {
  position: absolute;
  right: 5rem;
}

.m5-right-all *:not(:last-child) {
  margin-right: 5rem;
}

.m-right-5 {
  margin-right: 5rem;
}

.p-right-5 {
  padding-right: 5rem;
}

.fixed-right-6 {
  position: fixed;
  right: 6;
}

.fixed-right-6pc {
  position: fixed;
  right: 6%;
}

.fixed-right-60pc {
  position: fixed;
  right: 60%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-6 {
    position: fixed;
    right: 6;
  }
  .mobile-fixed-right-60pc {
    position: fixed;
    right: 60%;
  }
  .mobile-fixed-right-m60pc {
    position: fixed;
    right: -60%;
  }
  .mobile-abs-right-6 {
    position: absolute;
    right: 6;
  }
  .mobile-abs-right-60pc {
    position: absolute;
    right: 60%;
  }
}
.right-60pc {
  right: 60%;
}

.right-6 {
  right: 6rem;
}

.abs-right-6-neg {
  position: absolute;
  right: -6rem;
}

.abs-right-6 {
  position: absolute;
  right: 6rem;
}

.m6-right-all *:not(:last-child) {
  margin-right: 6rem;
}

.m-right-6 {
  margin-right: 6rem;
}

.p-right-6 {
  padding-right: 6rem;
}

.fixed-right-7 {
  position: fixed;
  right: 7;
}

.fixed-right-7pc {
  position: fixed;
  right: 7%;
}

.fixed-right-70pc {
  position: fixed;
  right: 70%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-7 {
    position: fixed;
    right: 7;
  }
  .mobile-fixed-right-70pc {
    position: fixed;
    right: 70%;
  }
  .mobile-fixed-right-m70pc {
    position: fixed;
    right: -70%;
  }
  .mobile-abs-right-7 {
    position: absolute;
    right: 7;
  }
  .mobile-abs-right-70pc {
    position: absolute;
    right: 70%;
  }
}
.right-70pc {
  right: 70%;
}

.right-7 {
  right: 7rem;
}

.abs-right-7-neg {
  position: absolute;
  right: -7rem;
}

.abs-right-7 {
  position: absolute;
  right: 7rem;
}

.m7-right-all *:not(:last-child) {
  margin-right: 7rem;
}

.m-right-7 {
  margin-right: 7rem;
}

.p-right-7 {
  padding-right: 7rem;
}

.fixed-right-8 {
  position: fixed;
  right: 8;
}

.fixed-right-8pc {
  position: fixed;
  right: 8%;
}

.fixed-right-80pc {
  position: fixed;
  right: 80%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-8 {
    position: fixed;
    right: 8;
  }
  .mobile-fixed-right-80pc {
    position: fixed;
    right: 80%;
  }
  .mobile-fixed-right-m80pc {
    position: fixed;
    right: -80%;
  }
  .mobile-abs-right-8 {
    position: absolute;
    right: 8;
  }
  .mobile-abs-right-80pc {
    position: absolute;
    right: 80%;
  }
}
.right-80pc {
  right: 80%;
}

.right-8 {
  right: 8rem;
}

.abs-right-8-neg {
  position: absolute;
  right: -8rem;
}

.abs-right-8 {
  position: absolute;
  right: 8rem;
}

.m8-right-all *:not(:last-child) {
  margin-right: 8rem;
}

.m-right-8 {
  margin-right: 8rem;
}

.p-right-8 {
  padding-right: 8rem;
}

.fixed-right-9 {
  position: fixed;
  right: 9;
}

.fixed-right-9pc {
  position: fixed;
  right: 9%;
}

.fixed-right-90pc {
  position: fixed;
  right: 90%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-9 {
    position: fixed;
    right: 9;
  }
  .mobile-fixed-right-90pc {
    position: fixed;
    right: 90%;
  }
  .mobile-fixed-right-m90pc {
    position: fixed;
    right: -90%;
  }
  .mobile-abs-right-9 {
    position: absolute;
    right: 9;
  }
  .mobile-abs-right-90pc {
    position: absolute;
    right: 90%;
  }
}
.right-90pc {
  right: 90%;
}

.right-9 {
  right: 9rem;
}

.abs-right-9-neg {
  position: absolute;
  right: -9rem;
}

.abs-right-9 {
  position: absolute;
  right: 9rem;
}

.m9-right-all *:not(:last-child) {
  margin-right: 9rem;
}

.m-right-9 {
  margin-right: 9rem;
}

.p-right-9 {
  padding-right: 9rem;
}

.fixed-right-10 {
  position: fixed;
  right: 10;
}

.fixed-right-10pc {
  position: fixed;
  right: 10%;
}

.fixed-right-100pc {
  position: fixed;
  right: 100%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-right-10 {
    position: fixed;
    right: 10;
  }
  .mobile-fixed-right-100pc {
    position: fixed;
    right: 100%;
  }
  .mobile-fixed-right-m100pc {
    position: fixed;
    right: -100%;
  }
  .mobile-abs-right-10 {
    position: absolute;
    right: 10;
  }
  .mobile-abs-right-100pc {
    position: absolute;
    right: 100%;
  }
}
.right-100pc {
  right: 100%;
}

.right-10 {
  right: 10rem;
}

.abs-right-10-neg {
  position: absolute;
  right: -10rem;
}

.abs-right-10 {
  position: absolute;
  right: 10rem;
}

.m10-right-all *:not(:last-child) {
  margin-right: 10rem;
}

.m-right-10 {
  margin-right: 10rem;
}

.p-right-10 {
  padding-right: 10rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-top {
    bottom: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-top {
    bottom: 0;
  }
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom {
    position: fixed;
    bottom: 0;
  }
  .mobile-h100 {
    height: 100%;
  }
}
.abs-bottom {
  position: absolute;
  bottom: 0;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #ccc;
}

@media only screen and (min-width: 1007px) {
  .fixed-bottom-top-desktop {
    position: fixed;
    bottom: 0;
    top: 0;
  }
}
.fixed-bottom-top {
  position: fixed;
  bottom: 0;
  top: 0;
}

.abs-bottom-top {
  position: absolute;
  bottom: 0;
  top: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-bottom-right-desktop {
    position: fixed;
    bottom: 0;
    right: 0;
  }
}
.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.abs-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-bottom-bottom-desktop {
    position: fixed;
    bottom: 0;
    bottom: 0;
  }
}
.fixed-bottom-bottom {
  position: fixed;
  bottom: 0;
  bottom: 0;
}

.abs-bottom-bottom {
  position: absolute;
  bottom: 0;
  bottom: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-bottom-left-desktop {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
.fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
}

.abs-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.mobile-bottom-auto {
  bottom: auto;
}

.fixed-bottom-0 {
  position: fixed;
  bottom: 0;
}

.fixed-bottom-0pc {
  position: fixed;
  bottom: 0%;
}

.fixed-bottom-00pc {
  position: fixed;
  bottom: 00%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-0 {
    position: fixed;
    bottom: 0;
  }
  .mobile-fixed-bottom-00pc {
    position: fixed;
    bottom: 00%;
  }
  .mobile-fixed-bottom-m00pc {
    position: fixed;
    bottom: -00%;
  }
  .mobile-abs-bottom-0 {
    position: absolute;
    bottom: 0;
  }
  .mobile-abs-bottom-00pc {
    position: absolute;
    bottom: 00%;
  }
}
.bottom-00pc {
  bottom: 00%;
}

.bottom-0 {
  bottom: 0rem;
}

.abs-bottom-0-neg {
  position: absolute;
  bottom: -0rem;
}

.abs-bottom-0 {
  position: absolute;
  bottom: 0rem;
}

.m0-bottom-all *:not(:last-child) {
  margin-bottom: 0rem;
}

.m-bottom-0 {
  margin-bottom: 0rem;
}

.p-bottom-0 {
  padding-bottom: 0rem;
}

.fixed-bottom-1 {
  position: fixed;
  bottom: 1;
}

.fixed-bottom-1pc {
  position: fixed;
  bottom: 1%;
}

.fixed-bottom-10pc {
  position: fixed;
  bottom: 10%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-1 {
    position: fixed;
    bottom: 1;
  }
  .mobile-fixed-bottom-10pc {
    position: fixed;
    bottom: 10%;
  }
  .mobile-fixed-bottom-m10pc {
    position: fixed;
    bottom: -10%;
  }
  .mobile-abs-bottom-1 {
    position: absolute;
    bottom: 1;
  }
  .mobile-abs-bottom-10pc {
    position: absolute;
    bottom: 10%;
  }
}
.bottom-10pc {
  bottom: 10%;
}

.bottom-1 {
  bottom: 1rem;
}

.abs-bottom-1-neg {
  position: absolute;
  bottom: -1rem;
}

.abs-bottom-1 {
  position: absolute;
  bottom: 1rem;
}

.m1-bottom-all *:not(:last-child) {
  margin-bottom: 1rem;
}

.m-bottom-1 {
  margin-bottom: 1rem;
}

.p-bottom-1 {
  padding-bottom: 1rem;
}

.fixed-bottom-2 {
  position: fixed;
  bottom: 2;
}

.fixed-bottom-2pc {
  position: fixed;
  bottom: 2%;
}

.fixed-bottom-20pc {
  position: fixed;
  bottom: 20%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-2 {
    position: fixed;
    bottom: 2;
  }
  .mobile-fixed-bottom-20pc {
    position: fixed;
    bottom: 20%;
  }
  .mobile-fixed-bottom-m20pc {
    position: fixed;
    bottom: -20%;
  }
  .mobile-abs-bottom-2 {
    position: absolute;
    bottom: 2;
  }
  .mobile-abs-bottom-20pc {
    position: absolute;
    bottom: 20%;
  }
}
.bottom-20pc {
  bottom: 20%;
}

.bottom-2 {
  bottom: 2rem;
}

.abs-bottom-2-neg {
  position: absolute;
  bottom: -2rem;
}

.abs-bottom-2 {
  position: absolute;
  bottom: 2rem;
}

.m2-bottom-all *:not(:last-child) {
  margin-bottom: 2rem;
}

.m-bottom-2 {
  margin-bottom: 2rem;
}

.p-bottom-2 {
  padding-bottom: 2rem;
}

.fixed-bottom-3 {
  position: fixed;
  bottom: 3;
}

.fixed-bottom-3pc {
  position: fixed;
  bottom: 3%;
}

.fixed-bottom-30pc {
  position: fixed;
  bottom: 30%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-3 {
    position: fixed;
    bottom: 3;
  }
  .mobile-fixed-bottom-30pc {
    position: fixed;
    bottom: 30%;
  }
  .mobile-fixed-bottom-m30pc {
    position: fixed;
    bottom: -30%;
  }
  .mobile-abs-bottom-3 {
    position: absolute;
    bottom: 3;
  }
  .mobile-abs-bottom-30pc {
    position: absolute;
    bottom: 30%;
  }
}
.bottom-30pc {
  bottom: 30%;
}

.bottom-3 {
  bottom: 3rem;
}

.abs-bottom-3-neg {
  position: absolute;
  bottom: -3rem;
}

.abs-bottom-3 {
  position: absolute;
  bottom: 3rem;
}

.m3-bottom-all *:not(:last-child) {
  margin-bottom: 3rem;
}

.m-bottom-3 {
  margin-bottom: 3rem;
}

.p-bottom-3 {
  padding-bottom: 3rem;
}

.fixed-bottom-4 {
  position: fixed;
  bottom: 4;
}

.fixed-bottom-4pc {
  position: fixed;
  bottom: 4%;
}

.fixed-bottom-40pc {
  position: fixed;
  bottom: 40%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-4 {
    position: fixed;
    bottom: 4;
  }
  .mobile-fixed-bottom-40pc {
    position: fixed;
    bottom: 40%;
  }
  .mobile-fixed-bottom-m40pc {
    position: fixed;
    bottom: -40%;
  }
  .mobile-abs-bottom-4 {
    position: absolute;
    bottom: 4;
  }
  .mobile-abs-bottom-40pc {
    position: absolute;
    bottom: 40%;
  }
}
.bottom-40pc {
  bottom: 40%;
}

.bottom-4 {
  bottom: 4rem;
}

.abs-bottom-4-neg {
  position: absolute;
  bottom: -4rem;
}

.abs-bottom-4 {
  position: absolute;
  bottom: 4rem;
}

.m4-bottom-all *:not(:last-child) {
  margin-bottom: 4rem;
}

.m-bottom-4 {
  margin-bottom: 4rem;
}

.p-bottom-4 {
  padding-bottom: 4rem;
}

.fixed-bottom-5 {
  position: fixed;
  bottom: 5;
}

.fixed-bottom-5pc {
  position: fixed;
  bottom: 5%;
}

.fixed-bottom-50pc {
  position: fixed;
  bottom: 50%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-5 {
    position: fixed;
    bottom: 5;
  }
  .mobile-fixed-bottom-50pc {
    position: fixed;
    bottom: 50%;
  }
  .mobile-fixed-bottom-m50pc {
    position: fixed;
    bottom: -50%;
  }
  .mobile-abs-bottom-5 {
    position: absolute;
    bottom: 5;
  }
  .mobile-abs-bottom-50pc {
    position: absolute;
    bottom: 50%;
  }
}
.bottom-50pc {
  bottom: 50%;
}

.bottom-5 {
  bottom: 5rem;
}

.abs-bottom-5-neg {
  position: absolute;
  bottom: -5rem;
}

.abs-bottom-5 {
  position: absolute;
  bottom: 5rem;
}

.m5-bottom-all *:not(:last-child) {
  margin-bottom: 5rem;
}

.m-bottom-5 {
  margin-bottom: 5rem;
}

.p-bottom-5 {
  padding-bottom: 5rem;
}

.fixed-bottom-6 {
  position: fixed;
  bottom: 6;
}

.fixed-bottom-6pc {
  position: fixed;
  bottom: 6%;
}

.fixed-bottom-60pc {
  position: fixed;
  bottom: 60%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-6 {
    position: fixed;
    bottom: 6;
  }
  .mobile-fixed-bottom-60pc {
    position: fixed;
    bottom: 60%;
  }
  .mobile-fixed-bottom-m60pc {
    position: fixed;
    bottom: -60%;
  }
  .mobile-abs-bottom-6 {
    position: absolute;
    bottom: 6;
  }
  .mobile-abs-bottom-60pc {
    position: absolute;
    bottom: 60%;
  }
}
.bottom-60pc {
  bottom: 60%;
}

.bottom-6 {
  bottom: 6rem;
}

.abs-bottom-6-neg {
  position: absolute;
  bottom: -6rem;
}

.abs-bottom-6 {
  position: absolute;
  bottom: 6rem;
}

.m6-bottom-all *:not(:last-child) {
  margin-bottom: 6rem;
}

.m-bottom-6 {
  margin-bottom: 6rem;
}

.p-bottom-6 {
  padding-bottom: 6rem;
}

.fixed-bottom-7 {
  position: fixed;
  bottom: 7;
}

.fixed-bottom-7pc {
  position: fixed;
  bottom: 7%;
}

.fixed-bottom-70pc {
  position: fixed;
  bottom: 70%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-7 {
    position: fixed;
    bottom: 7;
  }
  .mobile-fixed-bottom-70pc {
    position: fixed;
    bottom: 70%;
  }
  .mobile-fixed-bottom-m70pc {
    position: fixed;
    bottom: -70%;
  }
  .mobile-abs-bottom-7 {
    position: absolute;
    bottom: 7;
  }
  .mobile-abs-bottom-70pc {
    position: absolute;
    bottom: 70%;
  }
}
.bottom-70pc {
  bottom: 70%;
}

.bottom-7 {
  bottom: 7rem;
}

.abs-bottom-7-neg {
  position: absolute;
  bottom: -7rem;
}

.abs-bottom-7 {
  position: absolute;
  bottom: 7rem;
}

.m7-bottom-all *:not(:last-child) {
  margin-bottom: 7rem;
}

.m-bottom-7 {
  margin-bottom: 7rem;
}

.p-bottom-7 {
  padding-bottom: 7rem;
}

.fixed-bottom-8 {
  position: fixed;
  bottom: 8;
}

.fixed-bottom-8pc {
  position: fixed;
  bottom: 8%;
}

.fixed-bottom-80pc {
  position: fixed;
  bottom: 80%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-8 {
    position: fixed;
    bottom: 8;
  }
  .mobile-fixed-bottom-80pc {
    position: fixed;
    bottom: 80%;
  }
  .mobile-fixed-bottom-m80pc {
    position: fixed;
    bottom: -80%;
  }
  .mobile-abs-bottom-8 {
    position: absolute;
    bottom: 8;
  }
  .mobile-abs-bottom-80pc {
    position: absolute;
    bottom: 80%;
  }
}
.bottom-80pc {
  bottom: 80%;
}

.bottom-8 {
  bottom: 8rem;
}

.abs-bottom-8-neg {
  position: absolute;
  bottom: -8rem;
}

.abs-bottom-8 {
  position: absolute;
  bottom: 8rem;
}

.m8-bottom-all *:not(:last-child) {
  margin-bottom: 8rem;
}

.m-bottom-8 {
  margin-bottom: 8rem;
}

.p-bottom-8 {
  padding-bottom: 8rem;
}

.fixed-bottom-9 {
  position: fixed;
  bottom: 9;
}

.fixed-bottom-9pc {
  position: fixed;
  bottom: 9%;
}

.fixed-bottom-90pc {
  position: fixed;
  bottom: 90%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-9 {
    position: fixed;
    bottom: 9;
  }
  .mobile-fixed-bottom-90pc {
    position: fixed;
    bottom: 90%;
  }
  .mobile-fixed-bottom-m90pc {
    position: fixed;
    bottom: -90%;
  }
  .mobile-abs-bottom-9 {
    position: absolute;
    bottom: 9;
  }
  .mobile-abs-bottom-90pc {
    position: absolute;
    bottom: 90%;
  }
}
.bottom-90pc {
  bottom: 90%;
}

.bottom-9 {
  bottom: 9rem;
}

.abs-bottom-9-neg {
  position: absolute;
  bottom: -9rem;
}

.abs-bottom-9 {
  position: absolute;
  bottom: 9rem;
}

.m9-bottom-all *:not(:last-child) {
  margin-bottom: 9rem;
}

.m-bottom-9 {
  margin-bottom: 9rem;
}

.p-bottom-9 {
  padding-bottom: 9rem;
}

.fixed-bottom-10 {
  position: fixed;
  bottom: 10;
}

.fixed-bottom-10pc {
  position: fixed;
  bottom: 10%;
}

.fixed-bottom-100pc {
  position: fixed;
  bottom: 100%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-bottom-10 {
    position: fixed;
    bottom: 10;
  }
  .mobile-fixed-bottom-100pc {
    position: fixed;
    bottom: 100%;
  }
  .mobile-fixed-bottom-m100pc {
    position: fixed;
    bottom: -100%;
  }
  .mobile-abs-bottom-10 {
    position: absolute;
    bottom: 10;
  }
  .mobile-abs-bottom-100pc {
    position: absolute;
    bottom: 100%;
  }
}
.bottom-100pc {
  bottom: 100%;
}

.bottom-10 {
  bottom: 10rem;
}

.abs-bottom-10-neg {
  position: absolute;
  bottom: -10rem;
}

.abs-bottom-10 {
  position: absolute;
  bottom: 10rem;
}

.m10-bottom-all *:not(:last-child) {
  margin-bottom: 10rem;
}

.m-bottom-10 {
  margin-bottom: 10rem;
}

.p-bottom-10 {
  padding-bottom: 10rem;
}

@media only screen and (min-width: 1007px) {
  .desktop-top {
    left: 0;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-top {
    left: 0;
  }
}
.fixed-left {
  position: fixed;
  left: 0;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left {
    position: fixed;
    left: 0;
  }
  .mobile-h100 {
    height: 100%;
  }
}
.abs-left {
  position: absolute;
  left: 0;
}

.border-left {
  border-left: 1px solid #ccc;
}

.border-left-dashed {
  border-left: 1px dashed #ccc;
}

@media only screen and (min-width: 1007px) {
  .fixed-left-top-desktop {
    position: fixed;
    left: 0;
    top: 0;
  }
}
.fixed-left-top {
  position: fixed;
  left: 0;
  top: 0;
}

.abs-left-top {
  position: absolute;
  left: 0;
  top: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-left-right-desktop {
    position: fixed;
    left: 0;
    right: 0;
  }
}
.fixed-left-right {
  position: fixed;
  left: 0;
  right: 0;
}

.abs-left-right {
  position: absolute;
  left: 0;
  right: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-left-bottom-desktop {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
.fixed-left-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
}

.abs-left-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (min-width: 1007px) {
  .fixed-left-left-desktop {
    position: fixed;
    left: 0;
    left: 0;
  }
}
.fixed-left-left {
  position: fixed;
  left: 0;
  left: 0;
}

.abs-left-left {
  position: absolute;
  left: 0;
  left: 0;
}

.mobile-left-auto {
  left: auto;
}

.fixed-left-0 {
  position: fixed;
  left: 0;
}

.fixed-left-0pc {
  position: fixed;
  left: 0%;
}

.fixed-left-00pc {
  position: fixed;
  left: 00%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-0 {
    position: fixed;
    left: 0;
  }
  .mobile-fixed-left-00pc {
    position: fixed;
    left: 00%;
  }
  .mobile-fixed-left-m00pc {
    position: fixed;
    left: -00%;
  }
  .mobile-abs-left-0 {
    position: absolute;
    left: 0;
  }
  .mobile-abs-left-00pc {
    position: absolute;
    left: 00%;
  }
}
.left-00pc {
  left: 00%;
}

.left-0 {
  left: 0rem;
}

.abs-left-0-neg {
  position: absolute;
  left: -0rem;
}

.abs-left-0 {
  position: absolute;
  left: 0rem;
}

.m0-left-all *:not(:last-child) {
  margin-left: 0rem;
}

.m-left-0 {
  margin-left: 0rem;
}

.p-left-0 {
  padding-left: 0rem;
}

.fixed-left-1 {
  position: fixed;
  left: 1;
}

.fixed-left-1pc {
  position: fixed;
  left: 1%;
}

.fixed-left-10pc {
  position: fixed;
  left: 10%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-1 {
    position: fixed;
    left: 1;
  }
  .mobile-fixed-left-10pc {
    position: fixed;
    left: 10%;
  }
  .mobile-fixed-left-m10pc {
    position: fixed;
    left: -10%;
  }
  .mobile-abs-left-1 {
    position: absolute;
    left: 1;
  }
  .mobile-abs-left-10pc {
    position: absolute;
    left: 10%;
  }
}
.left-10pc {
  left: 10%;
}

.left-1 {
  left: 1rem;
}

.abs-left-1-neg {
  position: absolute;
  left: -1rem;
}

.abs-left-1 {
  position: absolute;
  left: 1rem;
}

.m1-left-all *:not(:last-child) {
  margin-left: 1rem;
}

.m-left-1 {
  margin-left: 1rem;
}

.p-left-1 {
  padding-left: 1rem;
}

.fixed-left-2 {
  position: fixed;
  left: 2;
}

.fixed-left-2pc {
  position: fixed;
  left: 2%;
}

.fixed-left-20pc {
  position: fixed;
  left: 20%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-2 {
    position: fixed;
    left: 2;
  }
  .mobile-fixed-left-20pc {
    position: fixed;
    left: 20%;
  }
  .mobile-fixed-left-m20pc {
    position: fixed;
    left: -20%;
  }
  .mobile-abs-left-2 {
    position: absolute;
    left: 2;
  }
  .mobile-abs-left-20pc {
    position: absolute;
    left: 20%;
  }
}
.left-20pc {
  left: 20%;
}

.left-2 {
  left: 2rem;
}

.abs-left-2-neg {
  position: absolute;
  left: -2rem;
}

.abs-left-2 {
  position: absolute;
  left: 2rem;
}

.m2-left-all *:not(:last-child) {
  margin-left: 2rem;
}

.m-left-2 {
  margin-left: 2rem;
}

.p-left-2 {
  padding-left: 2rem;
}

.fixed-left-3 {
  position: fixed;
  left: 3;
}

.fixed-left-3pc {
  position: fixed;
  left: 3%;
}

.fixed-left-30pc {
  position: fixed;
  left: 30%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-3 {
    position: fixed;
    left: 3;
  }
  .mobile-fixed-left-30pc {
    position: fixed;
    left: 30%;
  }
  .mobile-fixed-left-m30pc {
    position: fixed;
    left: -30%;
  }
  .mobile-abs-left-3 {
    position: absolute;
    left: 3;
  }
  .mobile-abs-left-30pc {
    position: absolute;
    left: 30%;
  }
}
.left-30pc {
  left: 30%;
}

.left-3 {
  left: 3rem;
}

.abs-left-3-neg {
  position: absolute;
  left: -3rem;
}

.abs-left-3 {
  position: absolute;
  left: 3rem;
}

.m3-left-all *:not(:last-child) {
  margin-left: 3rem;
}

.m-left-3 {
  margin-left: 3rem;
}

.p-left-3 {
  padding-left: 3rem;
}

.fixed-left-4 {
  position: fixed;
  left: 4;
}

.fixed-left-4pc {
  position: fixed;
  left: 4%;
}

.fixed-left-40pc {
  position: fixed;
  left: 40%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-4 {
    position: fixed;
    left: 4;
  }
  .mobile-fixed-left-40pc {
    position: fixed;
    left: 40%;
  }
  .mobile-fixed-left-m40pc {
    position: fixed;
    left: -40%;
  }
  .mobile-abs-left-4 {
    position: absolute;
    left: 4;
  }
  .mobile-abs-left-40pc {
    position: absolute;
    left: 40%;
  }
}
.left-40pc {
  left: 40%;
}

.left-4 {
  left: 4rem;
}

.abs-left-4-neg {
  position: absolute;
  left: -4rem;
}

.abs-left-4 {
  position: absolute;
  left: 4rem;
}

.m4-left-all *:not(:last-child) {
  margin-left: 4rem;
}

.m-left-4 {
  margin-left: 4rem;
}

.p-left-4 {
  padding-left: 4rem;
}

.fixed-left-5 {
  position: fixed;
  left: 5;
}

.fixed-left-5pc {
  position: fixed;
  left: 5%;
}

.fixed-left-50pc {
  position: fixed;
  left: 50%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-5 {
    position: fixed;
    left: 5;
  }
  .mobile-fixed-left-50pc {
    position: fixed;
    left: 50%;
  }
  .mobile-fixed-left-m50pc {
    position: fixed;
    left: -50%;
  }
  .mobile-abs-left-5 {
    position: absolute;
    left: 5;
  }
  .mobile-abs-left-50pc {
    position: absolute;
    left: 50%;
  }
}
.left-50pc {
  left: 50%;
}

.left-5 {
  left: 5rem;
}

.abs-left-5-neg {
  position: absolute;
  left: -5rem;
}

.abs-left-5 {
  position: absolute;
  left: 5rem;
}

.m5-left-all *:not(:last-child) {
  margin-left: 5rem;
}

.m-left-5 {
  margin-left: 5rem;
}

.p-left-5 {
  padding-left: 5rem;
}

.fixed-left-6 {
  position: fixed;
  left: 6;
}

.fixed-left-6pc {
  position: fixed;
  left: 6%;
}

.fixed-left-60pc {
  position: fixed;
  left: 60%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-6 {
    position: fixed;
    left: 6;
  }
  .mobile-fixed-left-60pc {
    position: fixed;
    left: 60%;
  }
  .mobile-fixed-left-m60pc {
    position: fixed;
    left: -60%;
  }
  .mobile-abs-left-6 {
    position: absolute;
    left: 6;
  }
  .mobile-abs-left-60pc {
    position: absolute;
    left: 60%;
  }
}
.left-60pc {
  left: 60%;
}

.left-6 {
  left: 6rem;
}

.abs-left-6-neg {
  position: absolute;
  left: -6rem;
}

.abs-left-6 {
  position: absolute;
  left: 6rem;
}

.m6-left-all *:not(:last-child) {
  margin-left: 6rem;
}

.m-left-6 {
  margin-left: 6rem;
}

.p-left-6 {
  padding-left: 6rem;
}

.fixed-left-7 {
  position: fixed;
  left: 7;
}

.fixed-left-7pc {
  position: fixed;
  left: 7%;
}

.fixed-left-70pc {
  position: fixed;
  left: 70%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-7 {
    position: fixed;
    left: 7;
  }
  .mobile-fixed-left-70pc {
    position: fixed;
    left: 70%;
  }
  .mobile-fixed-left-m70pc {
    position: fixed;
    left: -70%;
  }
  .mobile-abs-left-7 {
    position: absolute;
    left: 7;
  }
  .mobile-abs-left-70pc {
    position: absolute;
    left: 70%;
  }
}
.left-70pc {
  left: 70%;
}

.left-7 {
  left: 7rem;
}

.abs-left-7-neg {
  position: absolute;
  left: -7rem;
}

.abs-left-7 {
  position: absolute;
  left: 7rem;
}

.m7-left-all *:not(:last-child) {
  margin-left: 7rem;
}

.m-left-7 {
  margin-left: 7rem;
}

.p-left-7 {
  padding-left: 7rem;
}

.fixed-left-8 {
  position: fixed;
  left: 8;
}

.fixed-left-8pc {
  position: fixed;
  left: 8%;
}

.fixed-left-80pc {
  position: fixed;
  left: 80%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-8 {
    position: fixed;
    left: 8;
  }
  .mobile-fixed-left-80pc {
    position: fixed;
    left: 80%;
  }
  .mobile-fixed-left-m80pc {
    position: fixed;
    left: -80%;
  }
  .mobile-abs-left-8 {
    position: absolute;
    left: 8;
  }
  .mobile-abs-left-80pc {
    position: absolute;
    left: 80%;
  }
}
.left-80pc {
  left: 80%;
}

.left-8 {
  left: 8rem;
}

.abs-left-8-neg {
  position: absolute;
  left: -8rem;
}

.abs-left-8 {
  position: absolute;
  left: 8rem;
}

.m8-left-all *:not(:last-child) {
  margin-left: 8rem;
}

.m-left-8 {
  margin-left: 8rem;
}

.p-left-8 {
  padding-left: 8rem;
}

.fixed-left-9 {
  position: fixed;
  left: 9;
}

.fixed-left-9pc {
  position: fixed;
  left: 9%;
}

.fixed-left-90pc {
  position: fixed;
  left: 90%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-9 {
    position: fixed;
    left: 9;
  }
  .mobile-fixed-left-90pc {
    position: fixed;
    left: 90%;
  }
  .mobile-fixed-left-m90pc {
    position: fixed;
    left: -90%;
  }
  .mobile-abs-left-9 {
    position: absolute;
    left: 9;
  }
  .mobile-abs-left-90pc {
    position: absolute;
    left: 90%;
  }
}
.left-90pc {
  left: 90%;
}

.left-9 {
  left: 9rem;
}

.abs-left-9-neg {
  position: absolute;
  left: -9rem;
}

.abs-left-9 {
  position: absolute;
  left: 9rem;
}

.m9-left-all *:not(:last-child) {
  margin-left: 9rem;
}

.m-left-9 {
  margin-left: 9rem;
}

.p-left-9 {
  padding-left: 9rem;
}

.fixed-left-10 {
  position: fixed;
  left: 10;
}

.fixed-left-10pc {
  position: fixed;
  left: 10%;
}

.fixed-left-100pc {
  position: fixed;
  left: 100%;
}

@media only screen and (max-width: 1007px) {
  .mobile-fixed-left-10 {
    position: fixed;
    left: 10;
  }
  .mobile-fixed-left-100pc {
    position: fixed;
    left: 100%;
  }
  .mobile-fixed-left-m100pc {
    position: fixed;
    left: -100%;
  }
  .mobile-abs-left-10 {
    position: absolute;
    left: 10;
  }
  .mobile-abs-left-100pc {
    position: absolute;
    left: 100%;
  }
}
.left-100pc {
  left: 100%;
}

.left-10 {
  left: 10rem;
}

.abs-left-10-neg {
  position: absolute;
  left: -10rem;
}

.abs-left-10 {
  position: absolute;
  left: 10rem;
}

.m10-left-all *:not(:last-child) {
  margin-left: 10rem;
}

.m-left-10 {
  margin-left: 10rem;
}

.p-left-10 {
  padding-left: 10rem;
}

.hide-right {
  right: 0;
}

.fixed-top {
  top: 0;
}

.fixed-top-bottom {
  top: 0;
  bottom: 0;
}

.fixed-left-right {
  left: 0;
  right: 0;
}

.fixed-right {
  right: 0;
}

.snap-right {
  left: 100%;
  right: 0;
}

.z100 {
  z-index: 100;
}

@media only screen and (min-width: 1007px) {
  .desktop-z100 {
    z-index: 100;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z100 {
    z-index: 100;
  }
  .mobile-translate-x-100pc {
    transform: translateX(100%);
  }
  .mobile-translate-x-m100pc {
    transform: translateX(-100%);
  }
  .mobile-translate-y-100pc {
    transform: translateY(100%);
  }
  .mobile-translate-y-m100pc {
    transform: translateY(-100%);
  }
}
.z200 {
  z-index: 200;
}

@media only screen and (min-width: 1007px) {
  .desktop-z200 {
    z-index: 200;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z200 {
    z-index: 200;
  }
  .mobile-translate-x-200pc {
    transform: translateX(200%);
  }
  .mobile-translate-x-m200pc {
    transform: translateX(-200%);
  }
  .mobile-translate-y-200pc {
    transform: translateY(200%);
  }
  .mobile-translate-y-m200pc {
    transform: translateY(-200%);
  }
}
.z300 {
  z-index: 300;
}

@media only screen and (min-width: 1007px) {
  .desktop-z300 {
    z-index: 300;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z300 {
    z-index: 300;
  }
  .mobile-translate-x-300pc {
    transform: translateX(300%);
  }
  .mobile-translate-x-m300pc {
    transform: translateX(-300%);
  }
  .mobile-translate-y-300pc {
    transform: translateY(300%);
  }
  .mobile-translate-y-m300pc {
    transform: translateY(-300%);
  }
}
.z400 {
  z-index: 400;
}

@media only screen and (min-width: 1007px) {
  .desktop-z400 {
    z-index: 400;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z400 {
    z-index: 400;
  }
  .mobile-translate-x-400pc {
    transform: translateX(400%);
  }
  .mobile-translate-x-m400pc {
    transform: translateX(-400%);
  }
  .mobile-translate-y-400pc {
    transform: translateY(400%);
  }
  .mobile-translate-y-m400pc {
    transform: translateY(-400%);
  }
}
.z500 {
  z-index: 500;
}

@media only screen and (min-width: 1007px) {
  .desktop-z500 {
    z-index: 500;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z500 {
    z-index: 500;
  }
  .mobile-translate-x-500pc {
    transform: translateX(500%);
  }
  .mobile-translate-x-m500pc {
    transform: translateX(-500%);
  }
  .mobile-translate-y-500pc {
    transform: translateY(500%);
  }
  .mobile-translate-y-m500pc {
    transform: translateY(-500%);
  }
}
.z1000 {
  z-index: 1000;
}

@media only screen and (min-width: 1007px) {
  .desktop-z1000 {
    z-index: 1000;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-z1000 {
    z-index: 1000;
  }
  .mobile-translate-x-1000pc {
    transform: translateX(1000%);
  }
  .mobile-translate-x-m1000pc {
    transform: translateX(-1000%);
  }
  .mobile-translate-y-1000pc {
    transform: translateY(1000%);
  }
  .mobile-translate-y-m1000pc {
    transform: translateY(-1000%);
  }
}
.pad-all-right > * {
  padding: 0 0.5rem 0 0;
}

.pad-all--not-first > *:first-child {
  padding-left: 0;
}

.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.block-item {
  flex-grow: 1;
}

@media only screen and (max-width: 1007px) {
  .desktop-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .mobile-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1007px) {
  .desktop-relative {
    position: relative;
  }
}
:root {
  --theme-lighten-factor: 1;
  --charcoal-bg-color: #353535;
  --charcoal-fg-color: rgb(226, 226, 226);
  --navy-bg-color: #141728;
  --navy-fg-color: #ccc;
  --theme-alert-bg: goldenrod;
  --theme-alert-dark-bg: rgb(179, 128, 0);
  --theme-info-bg: rgb(0, 140, 255);
  --theme-info-dark-bg: rgb(0, 115, 205);
}

.alert, .button--alert {
  background-color: var(--theme-alert-bg);
}

.info, .button--info {
  background-color: var(--theme-info-bg);
}

.charcoal {
  background-color: #353535;
  color: rgb(226, 226, 226);
}

@media only screen and (max-width: 1007px) {
  .mobile-charcoal {
    background-color: var(--charcoal-bg-color);
    color: var(--charcoal-fg-color);
  }
  .mobile-charcoal .border-bottom-dashed {
    border-color: rgb(88, 88, 88);
  }
  .mobile-navy {
    background-color: var(--navy-bg-color);
    color: var(--navy-fg-color);
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-white {
    background-color: white;
  }
  .mobile-fg-white {
    background-color: white;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-white {
    background-color: white;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-black {
    background-color: black;
  }
  .mobile-fg-black {
    background-color: black;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-black {
    background-color: black;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-green {
    background-color: green;
  }
  .mobile-fg-green {
    background-color: green;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-green {
    background-color: green;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-red {
    background-color: red;
  }
  .mobile-fg-red {
    background-color: red;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-red {
    background-color: red;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-cyan {
    background-color: cyan;
  }
  .mobile-fg-cyan {
    background-color: cyan;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-cyan {
    background-color: cyan;
  }
}
@media only screen and (max-width: 1007px) {
  .mobile-bg-yellow {
    background-color: yellow;
  }
  .mobile-fg-yellow {
    background-color: yellow;
  }
}
@media only screen and (max-width: 1007px) {
  .desktop-bg-yellow {
    background-color: yellow;
  }
}
:root {
  --snapatoms-box-shadow: 0 0 1rem 0rem #eee;
  --snapatoms-border-radius: 0.5rem;
  --snapatoms-border-outline: #e5e7eb;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.justify-left {
  justify-content: flex-start;
}

.justify-right {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-italic {
  font-style: italic;
}

.caps {
  text-transform: capitalize;
}

.squared,
.squared * {
  border-radius: 0;
}

.rounded {
  border-radius: var(--snapatoms-border-radius);
}

html,
body {
  padding: 0;
  margin: 0;
}
