table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

td,
th {
  border-collapse: collapse;
  border: 1px solid #ccc;
  padding: 0.2rem 1rem;
}
